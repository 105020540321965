import {
  ExpandMoreRounded,
  SearchRounded,
  SentimentSatisfiedAltRounded,
} from "@mui/icons-material";
import { Box, Drawer, Tab } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ReactComponent as MenuSVG } from "../../images/menu-book-icon.svg";
import { useAppSelector } from "../../store/hooks";
import { useFilteredContentQuery } from "../../store/slices/healthkeyAPI";
import { NavigationTabsHomeProps } from "../../types/props";
import HamburgerDrawerSubItem from "../design-system/HamburgerDrawerSubItem";
import { StyledTabs } from "../design-system/StyledTabs";

type MenuDrawerProps = {
  children: React.ReactNode;

  open: boolean;

  setOpen: (b: boolean) => void;
};

const MenuDrawer = ({ children, open, setOpen }: MenuDrawerProps) => (
  <Drawer
    open={open}
    anchor="top"
    onClose={() => setOpen(false)}
    transitionDuration={500}
    sx={{
      "& .MuiBackdrop-root": {
        background: "transparent",
        opacity: 0,
      },
    }}
  >
    <Box
      sx={{
        padding: {
          lg: "24px 96px 40px",
          xl: "24px 248px 40px",
          xxl: "24px 296px 40px",
          xxxl: "24px 360px 40px",
        },
        display: "flex",
        flexDirection: "row",
      }}
    >
      {children}
    </Box>
  </Drawer>
);

const NavigationTabsHome = (props: NavigationTabsHomeProps) => {
  const navigate = useNavigate();

  const [value, setValue] = useState(props.selectedTab);

  const loggedInUser = useAppSelector((state) => state.userDetails.user);

  const { data: listOfContent } = useFilteredContentQuery({
    categories: [],
  });

  const { t } = useTranslation();

  const [platformOpen, setPlatformOpen] = useState(false);

  const [helpOpen, setHelpOpen] = useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const closeSubMenus = () => {
    setHelpOpen(false);
    setPlatformOpen(false);
  };

  return (
    <div role="navigation">
      <StyledTabs value={value ? value : false} onChange={handleChange}>
        {loggedInUser && !props.website ? (
          <Tab
            label={t("navigation.you")}
            tabIndex={1}
            value={"0"}
            component="button"
            icon={
              <SentimentSatisfiedAltRounded
                sx={{
                  width: "20px",
                  height: "20px",
                }}
              />
            }
            onClick={() => navigate("/you")}
            sx={{
              "&.MuiButtonBase-root": {
                height: "34px",
                paddingTop: "0",
                paddingBottom: "0",
                paddingX: { xs: "0.5rem", xl: "1.5rem" },
                minHeight: "34px",
              },
              ".MuiTab-iconWrapper": {
                marginLeft: { xs: "0.125rem", xl: "0.25rem" },
              },
            }}
          />
        ) : (
          <Tab
            label={t("navigation.platform.title")}
            tabIndex={1}
            value={"0"}
            component="button"
            icon={
              <ExpandMoreRounded
                sx={{
                  "&.MuiTab-iconWrapper": {
                    width: "20px",
                    height: "20px",
                  },
                }}
              />
            }
            onClick={() => {
              setPlatformOpen(!platformOpen);
              setHelpOpen(false);
            }}
            sx={{
              "&.MuiButtonBase-root": {
                height: "34px",
                paddingTop: "0",
                paddingBottom: "0",
                paddingX: { xs: "0.5rem", xl: "1.5rem" },
                minHeight: "34px",
              },
              ".MuiTab-iconWrapper": {
                marginLeft: { xs: "0.125rem", xl: "0.25rem" },
              },
            }}
          />
        )}
        {loggedInUser && !props.website ? (
          <Tab
            label={t("navigation.browse-care")}
            tabIndex={1}
            value={"1"}
            component="button"
            icon={
              <SearchRounded
                sx={{
                  width: "20px",
                  height: "20px",
                }}
              />
            }
            onClick={() => navigate("/findCare")}
            sx={{
              "&.MuiButtonBase-root": {
                height: "34px",
                paddingTop: "0",
                paddingBottom: "0",
                paddingX: { xs: "0.5rem", xl: "1.5rem" },
                minHeight: "34px",
              },
              ".MuiTab-iconWrapper": {
                marginLeft: { xs: "0.125rem", xl: "0.25rem" },
              },
            }}
          />
        ) : (
          <Tab
            label={t("navigation.browse-care")}
            tabIndex={1}
            value={"1"}
            component="button"
            onClick={() => {
              closeSubMenus();
              navigate("/findCare");
            }}
            sx={{
              "&.MuiButtonBase-root": {
                height: "34px",
                paddingTop: "0",
                paddingBottom: "0",
                paddingX: { xs: "0.5rem", xl: "1.5rem" },
                minHeight: "34px",
              },
            }}
          />
        )}
        {loggedInUser &&
          !props.website &&
          listOfContent &&
          listOfContent.length > 0 && (
            <Tab
              label={t("navigation.content")}
              tabIndex={1}
              value={"2"}
              component="button"
              icon={<MenuSVG className="ml-0.5 xl:ml-1 w-[20px] h-[20px]" />}
              onClick={() => navigate("/content")}
              sx={{
                "&.MuiButtonBase-root": {
                  height: "34px",
                  paddingTop: "0",
                  paddingBottom: "0",
                  paddingX: { xs: "0.5rem", xl: "1.5rem" },
                  minHeight: "34px",
                },
              }}
            />
          )}

        {(!loggedInUser || props.website) && (
          <Tab
            label={t("navigation.help-resources.title")}
            tabIndex={1}
            value={"2"}
            component="button"
            icon={
              <ExpandMoreRounded
                sx={{
                  width: "20px",
                  height: "20px",
                }}
              />
            }
            onClick={() => {
              setPlatformOpen(false);
              setHelpOpen(!helpOpen);
            }}
            sx={{
              "&.MuiButtonBase-root": {
                height: "34px",
                paddingTop: "0",
                paddingBottom: "0",
                paddingX: { xs: "0.5rem", xl: "1.5rem" },
                minHeight: "34px",
              },
              ".MuiTab-iconWrapper": {
                marginLeft: { xs: "0.125rem", xl: "0.25rem" },
              },
            }}
          />
        )}
        {(!loggedInUser || props.website) && (
          <Tab
            label={t("navigation.pricing")}
            tabIndex={1}
            value={"3"}
            component="button"
            onClick={() => {
              closeSubMenus();
              navigate("/pricing");
            }}
            sx={{
              "&.MuiButtonBase-root": {
                height: "34px",

                paddingTop: "0",
                paddingBottom: "0",
                paddingX: { xs: "0.5rem", xl: "1.5rem" },
                minHeight: "34px",
              },
            }}
          />
        )}
        {(!loggedInUser || props.website) && (
          <Tab
            label={t("navigation.about")}
            tabIndex={1}
            value={"4"}
            component="button"
            onClick={() => {
              closeSubMenus();
              navigate("/about");
            }}
            sx={{
              "&.MuiButtonBase-root": {
                height: "34px",
                paddingTop: "0",
                paddingBottom: "0",
                paddingX: { xs: "0.5rem", xl: "1.5rem" },
                minHeight: "34px",
              },
            }}
          />
        )}
      </StyledTabs>
      <MenuDrawer open={helpOpen} setOpen={setHelpOpen}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "40px",
          }}
        >
          <HamburgerDrawerSubItem
            title={t("navigation.help-resources.faqs")}
            subTitle={t("navigation.help-resources.faqs-description")}
            onClick={() => navigate("/help/FAQs")}
          />
          <HamburgerDrawerSubItem
            title={t("navigation.help-resources.contact")}
            subTitle={t("navigation.help-resources.contact-description")}
            onClick={() => navigate("/help/contact")}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <HamburgerDrawerSubItem
            title={t("navigation.help-resources.api-docs")}
            subTitle={t("navigation.help-resources.api-docs-description")}
            onClick={() =>
              window.open(process.env.REACT_APP_API_DOCS_URL, "_blank")
            }
          />
          <HamburgerDrawerSubItem
            title={t("navigation.help-resources.careers")}
            subTitle={t("navigation.help-resources.careers-description")}
            onClick={() =>
              window.open(process.env.REACT_APP_CAREERS_PAGE_URL, "_blank")
            }
          />
        </Box>
      </MenuDrawer>
      <MenuDrawer open={platformOpen} setOpen={setPlatformOpen}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginRight: "40px",
          }}
        >
          <HamburgerDrawerSubItem
            title={t("navigation.platform.employers")}
            subTitle={t("navigation.platform.employers-description")}
            onClick={() => navigate("/home/employers")}
          />
          <HamburgerDrawerSubItem
            title={t("navigation.platform.users")}
            subTitle={t("navigation.platform.users-description")}
            onClick={() => navigate("/home/individuals")}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <HamburgerDrawerSubItem
            title={t("navigation.platform.providers")}
            subTitle={t("navigation.platform.providers-description")}
            onClick={() => navigate("/home/providers")}
          />
        </Box>
      </MenuDrawer>
    </div>
  );
};

export default NavigationTabsHome;
