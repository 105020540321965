import { ArrowForwardRounded } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";

export type HamburgerDrawerSubItemProps = {
  title: string;

  subTitle: string;
  onClick: () => void;
};
const HamburgerDrawerSubItem = ({
  title,
  subTitle,
  onClick,
}: HamburgerDrawerSubItemProps) => (
  <div
    aria-pressed="false"
    role="button"
    tabIndex={0}
    className="flex flex-row py-4 px-4 cursor-pointer w-full hover:bg-blue hover:rounded-[4px] max-w-[452px]"
    onClick={onClick}
    onKeyDown={(event) => {
      if (event.key === "Enter") {
        onClick();
      }
    }}
  >
    <ArrowForwardRounded />
    <Box sx={{ marginLeft: "16px", display: "flex", flexDirection: "column" }}>
      <Typography variant="bodyBold">{title}</Typography>
      <Typography variant="body">{subTitle}</Typography>
    </Box>
  </div>
);

export default HamburgerDrawerSubItem;
