export const getSearchHistory = (): { query: string; date: string }[] => {
  return JSON.parse(localStorage.getItem("search-history") ?? "[]").map(
    (query) => ({
      ...query,
      date: new Date(query.date),
    }),
  );
};

export const addToSearchHistory = (
  query: string,
): { query: string; date: string }[] => {
  const searchHistory = [
    ...getSearchHistory(),
    {
      query,
      date: new Date().toISOString(),
    },
  ];
  localStorage.setItem("search-history", JSON.stringify(searchHistory));
  return searchHistory;
};

export const deleteSearchHistory = () => {
  localStorage.setItem("search-history", "[]");
};
