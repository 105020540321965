import { Link } from "@mui/material";
import React from "react";

import { ReactComponent as LogoSVG } from "./../../images/Logo_with_text.svg";

const LogoBanner = () => {
  return (
    <div
      role="banner"
      className="flex flex-row sm:justify-center w-full pt-7 pb-4 md:pb-5 h-[80px] px-3"
    >
      <Link href="/">
        <LogoSVG />
      </Link>
    </div>
  );
};

export default LogoBanner;
