import CircleLoader2 from "./new/CircleLoader2";

export const PageLoader = () => {
  return (
    <div
      data-testid="page-loader"
      className="fixed top-0 bottom-0 left-0 right-0 z-[2000] flex justify-center items-center bg-white"
    >
      <CircleLoader2 />
    </div>
  );
};
