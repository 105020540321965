import {
  ArrowForwardRounded,
  ChevronLeftRounded,
  ChevronRightRounded,
  CloseRounded,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { Box, Button, Drawer, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import HamburgerDrawerSubItem from "./HamburgerDrawerSubItem";

type HamburgerDrawerItemProps = {
  children: React.ReactNode;

  onClick: () => void;
};

const HamburgerDrawerItem = ({
  children,
  onClick,
}: HamburgerDrawerItemProps) => (
  <button
    aria-pressed="false"
    className="flex flex-row py-3 cursor-pointer items-center"
    onClick={onClick}
    onKeyDown={(event) => {
      if (event.key === "Enter") {
        onClick();
      }
    }}
  >
    {children}
  </button>
);

const HamburgerDrawer = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const [platformOpen, setPlatformOpen] = useState(false);

  const [helpOpen, setHelpOpen] = useState(false);

  const GoBack = () => {
    return (
      <button
        aria-pressed="false"
        className="flex flex-row py-3 px-5 mt-5 cursor-pointer items-center"
        onClick={() => {
          setPlatformOpen(false);
          setHelpOpen(false);
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            setPlatformOpen(false);
            setHelpOpen(false);
          }
        }}
      >
        <ChevronLeftRounded />
        <Typography variant="bodyBold">{t("navigation.go-back")}</Typography>
      </button>
    );
  };
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
    if (!newOpen) {
      setHelpOpen(false);
      setPlatformOpen(false);
    }
  };
  return (
    <div className="flex flex-1 flex-row justify-end">
      <div role="menubar">
        <button
          className="px-[24px] py-[20px] lg:py-0"
          onClick={toggleDrawer(!open)}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          tabIndex={0}
          aria-expanded={open ? "true" : undefined}
        >
          {open ? (
            <CloseRounded
              sx={{
                color: "#2C2C2C",
                width: "32px",
                height: "32px",
              }}
            />
          ) : (
            <MenuIcon
              sx={{
                color: "#2C2C2C",
                width: "32px",
                height: "32px",
              }}
            />
          )}
        </button>
        <Drawer
          open={open}
          anchor="top"
          onClose={toggleDrawer(false)}
          sx={{
            "& .MuiBackdrop-root": {
              background: "transparent",
              opacity: 0,
            },
          }}
        >
          {!platformOpen && !helpOpen && (
            <Box
              sx={{
                padding: "16px 20px 32px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <HamburgerDrawerItem onClick={() => setPlatformOpen(true)}>
                <Typography variant="bodyBold">
                  {t("navigation.platform.title")}
                </Typography>
                <ChevronRightRounded />
              </HamburgerDrawerItem>
              <HamburgerDrawerItem onClick={() => navigate("/findCare")}>
                <Typography variant="bodyBold">
                  {t("navigation.browse-care")}
                </Typography>
              </HamburgerDrawerItem>
              <HamburgerDrawerItem onClick={() => setHelpOpen(true)}>
                <Typography variant="bodyBold">
                  {t("navigation.help-resources.title")}
                </Typography>
                <ChevronRightRounded />
              </HamburgerDrawerItem>
              <HamburgerDrawerItem onClick={() => navigate("/pricing")}>
                <Typography variant="bodyBold">
                  {t("navigation.pricing")}
                </Typography>
              </HamburgerDrawerItem>
              <HamburgerDrawerItem onClick={() => navigate("/about")}>
                <Typography variant="bodyBold">
                  {t("navigation.about")}
                </Typography>
              </HamburgerDrawerItem>
              <Button
                variant="contained"
                onClick={() => navigate("/login")}
                endIcon={
                  <ArrowForwardRounded
                    sx={{
                      width: "20px",
                      height: "20px",
                    }}
                  />
                }
                sx={{
                  margin: "20px 0px 24px",
                  maxWidth: "400px",
                }}
              >
                Sign Up
              </Button>
              <Button
                variant="outlined"
                onClick={() => navigate("/login")}
                sx={{ marginBottom: "20px", maxWidth: "400px" }}
              >
                Login
              </Button>
            </Box>
          )}
          {platformOpen && (
            <Box>
              <GoBack />
              <Box sx={{ padding: "24px 40px" }}>
                <HamburgerDrawerSubItem
                  title={t("navigation.platform.employers")}
                  subTitle={t("navigation.platform.employers-description")}
                  onClick={() => navigate("/home/employers")}
                />
                <HamburgerDrawerSubItem
                  title={t("navigation.platform.users")}
                  subTitle={t("navigation.platform.users-description")}
                  onClick={() => navigate("/home/individuals")}
                />
                <HamburgerDrawerSubItem
                  title={t("navigation.platform.providers")}
                  subTitle={t("navigation.platform.providers-description")}
                  onClick={() => navigate("/home/providers")}
                />
              </Box>
            </Box>
          )}
          {helpOpen && (
            <Box>
              <GoBack />
              <Box sx={{ padding: "24px 40px" }}>
                <HamburgerDrawerSubItem
                  title={t("navigation.help-resources.faqs")}
                  subTitle={t("navigation.help-resources.faqs-description")}
                  onClick={() => navigate("/help/FAQs")}
                />
                <HamburgerDrawerSubItem
                  title={t("navigation.help-resources.contact")}
                  subTitle={t("navigation.help-resources.contact-description")}
                  onClick={() => navigate("/help/contact")}
                />
                <HamburgerDrawerSubItem
                  title={t("navigation.help-resources.api-docs")}
                  subTitle={t("navigation.help-resources.api-docs-description")}
                  onClick={() =>
                    window.open(process.env.REACT_APP_API_DOCS_URL, "_blank")
                  }
                />
                <HamburgerDrawerSubItem
                  title={t("navigation.help-resources.careers")}
                  subTitle={t("navigation.help-resources.careers-description")}
                  onClick={() =>
                    window.open(
                      process.env.REACT_APP_CAREERS_PAGE_URL,
                      "_blank",
                    )
                  }
                />
              </Box>
            </Box>
          )}
        </Drawer>
      </div>
    </div>
  );
};

export default HamburgerDrawer;
