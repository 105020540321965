import {
  ArrowForwardRounded,
  ExitToAppRounded,
  ExpandMoreRounded,
  FeedOutlined,
  LocationCityRounded,
  MailOutlineRounded,
  SearchRounded,
  SentimentSatisfiedAltRounded,
  SettingsRounded,
  SupervisorAccountRounded,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Menu,
  MenuItem,
  Paper,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { ForwardedRef, forwardRef, lazy, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as HeaderLogoWithTextSVG } from "../images/Logo_with_text.svg";
import { ReactComponent as HeaderLogoWithoutTextSVG } from "../images/Logos_no_text.svg";
import { truncate } from "../services/utils-service";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import authSlice from "../store/slices/auth";
import { EmployerForUser } from "../types/types";
import HamburgerDrawer from "./design-system/HamburgerDrawer";
import NavigationTabsHeader from "./new/NavigationTabsHeader";

const HealthBudgetHeaderComponent = lazy(
  () => import("./new/HealthBudgetHeaderComponent"),
);

export interface HeaderProps {
  title: string;

  selectedTab?: string;

  showHealthBudget: boolean;

  showSkipToBody: boolean;

  showSkipToSearch: boolean;

  showSkipToProviders: boolean;

  employer: EmployerForUser | null;

  hamburgerOverride: boolean;
}

const AccountMenu = ({
  website,
  employer,
}: {
  website: boolean;
  employer: EmployerForUser | null;
}) => {
  const { t } = useTranslation();

  const loggedInUser = useAppSelector((state) => state.userDetails.user);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const isBiggerThanLgBreakpoint = useMediaQuery(theme.breakpoints.up("lg"));

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleLogout = () => {
    dispatch(authSlice.actions.logout());
    navigate("/login");
  };
  const handleClickAccount = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseAccount = () => {
    setAnchorEl(null);
  };
  return (
    <div role="menubar" className="flex flex-row justify-center items-center">
      {isBiggerThanLgBreakpoint ? (
        <Button
          variant="outlined"
          endIcon={<ExpandMoreRounded />}
          onClick={handleClickAccount}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          data-testid="account-menu-button"
          sx={{
            paddingX: "12px",
            paddingY: "8px",
            height: "40px",
            marginY: { xs: "14px", lg: "0px" },
          }}
        >
          {t("navigation.account.title")}
        </Button>
      ) : (
        <button
          tabIndex={1}
          onClick={handleClickAccount}
          aria-label={t("navigation.account.title")}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          data-testid="account-menu-button"
          className="px-4"
        >
          <MenuIcon sx={{ color: "#2C2C2C", width: "24px", height: "24px" }} />
        </button>
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseAccount}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        elevation={1}
      >
        <MenuItem
          sx={{ width: "216px" }}
          role="menuitem"
          onClick={() => navigate("/profile/personal")}
        >
          <Typography variant="bodyBold">
            {t("navigation.account.account-settings")}
          </Typography>
          <SettingsRounded sx={{ marginLeft: "auto" }} />
        </MenuItem>
        {employer?.isAdmin && !website && (
          <MenuItem
            role="menuitem"
            onClick={() => navigate("/employerPortal/dashboard")}
          >
            <Typography variant="bodyBold">
              {employer?.name
                ? truncate(employer?.name, 19)
                : t("navigation.employer-settings")}
            </Typography>
            <LocationCityRounded sx={{ marginLeft: "auto" }} />
          </MenuItem>
        )}
        {loggedInUser?.provider_id && !website && (
          <MenuItem
            role="menuitem"
            onClick={() => navigate("/healthPartner/dashboard")}
          >
            <Typography variant="bodyBold">
              {t("navigation.provider-settings")}
            </Typography>
            <LocationCityRounded sx={{ marginLeft: "auto" }} />
          </MenuItem>
        )}
        {loggedInUser?.is_staff && !website && (
          <MenuItem
            role="menuitem"
            onClick={() => navigate("/adminPortal/home")}
          >
            <Typography variant="bodyBold">
              {t("navigation.admin-portal")}
            </Typography>
            <SupervisorAccountRounded sx={{ marginLeft: "auto" }} />
          </MenuItem>
        )}
        <MenuItem role="menuitem" onClick={() => navigate("/help/contact")}>
          <Typography variant="bodyBold">
            {t("navigation.account.contact")}
          </Typography>
          <MailOutlineRounded sx={{ marginLeft: "auto" }} />
        </MenuItem>
        <MenuItem role="menuitem" onClick={handleLogout}>
          <Typography variant="bodyBold">
            {t("navigation.account.logout")}
          </Typography>
          <ExitToAppRounded sx={{ marginLeft: "auto" }} />
        </MenuItem>
      </Menu>
    </div>
  );
};

const Header = forwardRef(function InnerHeader(
  props: HeaderProps,
  ref: ForwardedRef<HTMLElement>,
) {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const loggedInUser = useAppSelector((state) => state.userDetails.user);
  const [value, setValue] = useState(props.selectedTab);

  const website: boolean =
    location !== undefined &&
    location.pathname !== undefined &&
    (location.pathname.includes("home") ||
      location.pathname.includes("help") ||
      location.pathname.includes("pricing") ||
      (location.pathname.includes("findCare") && loggedInUser === null) ||
      location.pathname.includes("about") ||
      location.pathname === "/");
  const theme = useTheme();
  const isBiggerThanLgBreakpoint = useMediaQuery(theme.breakpoints.up("lg"));
  const isBiggerThanSmBreakpoint = useMediaQuery(theme.breakpoints.up("sm"));

  const showHamburger = !isBiggerThanLgBreakpoint || props.hamburgerOverride;
  // const showHamburger = false;

  return (
    <>
      <AppBar
        ref={ref}
        sx={{
          background: "#fff",
          paddingY: {
            lg: "12px",
          },
        }}
      >
        <Toolbar
          sx={{
            width: "100%",
            maxWidth: {
              xl: "1380px",
              xxxl: "1500px",
            },
            paddingX: {
              xs: "0",
              sm: "32px",
              md: "40px",
            },
            marginX: "auto",
            display: "flex",
            flexDirection: "column",
            "&.MuiToolbar-root": {
              minHeight: "56px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <div className="flex-1 lg:flex-initial flex flex-row items-center gap-3 sm:gap-6">
              <button
                tabIndex={1}
                aria-label="Home"
                aria-pressed="false"
                className="cursor-pointer pl-[16px] md:pl-0"
                onClick={() => navigate(!website ? "/you" : "/")}
                onKeyDown={(event) => {
                  if (event.key === "Enter") navigate(!website ? "/you" : "/");
                }}
              >
                {isBiggerThanSmBreakpoint ? (
                  <HeaderLogoWithTextSVG className="w-[140px] xl:w-[164px]" />
                ) : loggedInUser ? (
                  <HeaderLogoWithoutTextSVG />
                ) : (
                  <HeaderLogoWithTextSVG className="w-[140px] xl:w-[164px]" />
                )}
              </button>
              {props.employer?.cobrandingLogo && (
                <>
                  <div className="w-[1px] bg-[#CEE0FD] h-[40px]"></div>
                  <img
                    className="w-full max-w-[95px]"
                    src={props.employer?.cobrandingLogo ?? ""}
                    alt={`${props.employer?.cobrandingName} Logo`}
                  />
                </>
              )}
            </div>
            {!showHamburger && (
              <div className="flex-1 flex flex-row flex-wrap items-center justify-between pl-4 xl:pl-6">
                <NavigationTabsHeader
                  employer={props.employer}
                  selectedTab={props.selectedTab}
                  website={website}
                />
                <div className="focus:flex-1">
                  {props.showSkipToBody && (
                    <a href="#firstContent" tabIndex={1} className="skip-link">
                      Skip to body
                    </a>
                  )}
                  {props.showSkipToProviders && (
                    <a href="#providersList" tabIndex={1} className="skip-link">
                      Skip to providers list
                    </a>
                  )}
                  {props.showSkipToSearch && (
                    <a href="#searchBar" tabIndex={1} className="skip-link">
                      Skip to search
                    </a>
                  )}
                </div>
                <div className="flex flex-row gap-4">
                  {loggedInUser && !website && <HealthBudgetHeaderComponent />}
                  {loggedInUser && !website ? (
                    <AccountMenu website={website} employer={props.employer} />
                  ) : (
                    <div className="flex flex-row items-center gap-2 xl:gap-4">
                      <Button
                        tabIndex={1}
                        variant="outlined"
                        onClick={() => navigate("/login")}
                        sx={{
                          textWrap: "nowrap",
                        }}
                      >
                        {t("navigation.login")}
                      </Button>
                      <Button
                        tabIndex={1}
                        variant="contained"
                        onClick={() => navigate("/login")}
                        sx={{
                          textWrap: "nowrap",
                          display: "flex",
                          flexDirection: "row",
                          gap: "4px",
                        }}
                      >
                        {t("navigation.sign-up")}
                        <ArrowForwardRounded
                          sx={{
                            width: "20px",
                            height: "20px",
                          }}
                        />
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            )}
            {showHamburger &&
              (loggedInUser && !website ? (
                <div className="flex flex-row items-center gap-4">
                  <HealthBudgetHeaderComponent />
                  <AccountMenu website={website} employer={props.employer} />
                </div>
              ) : (
                <HamburgerDrawer />
              ))}
          </Box>
        </Toolbar>
      </AppBar>
      <Paper
        className={`bg-white rounded-[32px] border-gray border px-4 py-4 z-30 ${showHamburger && !website ? "block" : "hidden"}`}
        sx={{
          margin: "1rem 0.5rem",
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          borderRadius: "32px",
        }}
        elevation={0}
      >
        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          showLabels
        >
          <BottomNavigationAction
            label={t("navigation.you")}
            icon={<SentimentSatisfiedAltRounded />}
            value={"0"}
            component={Link}
            to={"/you"}
          />
          <BottomNavigationAction
            label={t("navigation.browse-care")}
            icon={<SearchRounded />}
            value={"1"}
            component={Link}
            to={"/findCare"}
          />
          <BottomNavigationAction
            label={t("navigation.content")}
            icon={<FeedOutlined />}
            value={"2"}
            component={Link}
            to={"/content"}
          />
        </BottomNavigation>
      </Paper>
    </>
  );
});

export default Header;
