import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { UserSubscription } from "../../types/types";

type SubscriptionsState = {
  activeSubscriptions: Array<UserSubscription>;
  pastSubscriptions: Array<UserSubscription>;
  renewSoonSubscriptions: Array<UserSubscription>;

  allSubscriptions: Array<UserSubscription>;
};

const initialState: SubscriptionsState = {
  activeSubscriptions: new Array<UserSubscription>(),
  pastSubscriptions: new Array<UserSubscription>(),
  renewSoonSubscriptions: new Array<UserSubscription>(),
  allSubscriptions: new Array<UserSubscription>(),
};

const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    setSubscriptions(
      state: SubscriptionsState,
      action: PayloadAction<UserSubscription[]>,
    ) {
      state.activeSubscriptions = new Array<UserSubscription>();
      state.pastSubscriptions = new Array<UserSubscription>();
      state.renewSoonSubscriptions = new Array<UserSubscription>();
      state.allSubscriptions = new Array<UserSubscription>();
      let subscriptions = action.payload;
      for (let s of subscriptions) {
        if (s.active) {
          state.allSubscriptions.push(s);
          state.activeSubscriptions.push(s);
          const date = new Date();
          let nextWeek = new Date(date.getTime() + 7 * 24 * 60 * 60 * 1000);
          let dateParts = s.nextPayment.toString().split("/");
          // month is 0-based, that's why we need dataParts[1] - 1
          let renewalDate = new Date(
            +dateParts[2],
            +dateParts[1] - 1,
            +dateParts[0],
          );

          if (renewalDate.getTime() < nextWeek.getTime()) {
            state.renewSoonSubscriptions.push(s);
          }
        } else {
          state.pastSubscriptions.push(s);
        }
      }
      for (let s of state.pastSubscriptions) {
        state.allSubscriptions.push(s);
      }
    },
  },
});

export default subscriptionsSlice;
