import { Typography } from "@mui/material";

export type TitleProps = {
  children: React.ReactNode;
};

export const TitleXL = (props: TitleProps) => {
  return (
    <Typography
      component="h1"
      variant="titleXL"
      sx={{
        paddingBottom: "24px",
      }}
    >
      {props.children}
    </Typography>
  );
};
