import { motion } from "framer-motion";
import React from "react";

export default function CircleLoader2() {
  return (
    <div
      style={{
        position: "relative",
        width: "5rem",
        height: "5rem",
        boxSizing: "border-box",
      }}
    >
      <motion.span
        style={{
          display: "block",
          width: "5rem",
          height: "5rem",
          border: "0.5rem solid #e9e9e9",
          borderTop: "0.5rem solid #FB6E67",
          borderRadius: "50%",
          position: "absolute",
          boxSizing: "border-box",
          top: 0,
          left: 0,
        }}
        animate={{ rotate: 360 }}
        transition={{
          repeatType: "loop",
          repeat: 15,
          ease: "linear",
          duration: 1,
        }}
      />
    </div>
  );
}
