import { Typography } from "@mui/material";
import { Trans } from "react-i18next";

import LogoBanner from "../new/LogoCenteredInsideComponent";

export const OnboardingContentBubble = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <>
      <div className="fixed inset-x-0 top-0 bg-[#C9EEC9] flex flex-col items-center justify-center px-3 py-2 z-10">
        <Typography sx={{ color: "#056753" }}>
          <Trans
            i18nKey="employer-portal.onboarding.contact-us"
            components={{
              contact: (
                // Anchor is given content by the Trans component
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  className="underline"
                  style={{ color: "#056753", fontWeight: "700" }}
                  href={process.env.REACT_APP_EMPLOYER_ONBOARDING_CONTACT_US}
                  rel="noreferrer"
                  target="_blank"
                />
              ),
            }}
          />
        </Typography>
      </div>
      <div className="flex flex-col items-center sm:p-12 pt-4 rounded-lg mt-[40px] sm:mt-0 w-full flex-1 xl:max-w-[1380px] 3xl:max-w-[1500px] xl:mx-auto">
        <LogoBanner />
        {children}
      </div>
    </>
  );
};
