import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  Employer,
  EmployerForUser,
  EmployerInformation,
  Provider,
  UpdateUser,
} from "../../types/types";

type EmployerState = {
  loggedInUsersEmployer: EmployerForUser | null;

  currentEmployer: Employer | null;

  employerInformation: EmployerInformation | null;

  selectedProviders: Provider[];

  allEmployers: Employer[];
};

const initialState: EmployerState = {
  loggedInUsersEmployer: null,
  employerInformation: null,
  selectedProviders: new Array<Provider>(),
  allEmployers: [],
  currentEmployer: null,
};

const employerSlice = createSlice({
  name: "employer",
  initialState,
  reducers: {
    setLoggedInUsersEmployer(
      state: EmployerState,
      action: PayloadAction<EmployerForUser>,
    ) {
      state.loggedInUsersEmployer = action.payload;
    },
    setEmployerInformation(
      state: EmployerState,
      action: PayloadAction<EmployerInformation>,
    ) {
      state.employerInformation = action.payload;
    },
    addTopUp(state: EmployerState, action: PayloadAction<number>) {
      if (state.employerInformation) {
        state.employerInformation.employerTopUp =
          +state.employerInformation.employerTopUp + +action.payload;
      }
    },
    updateUser(state: EmployerState, action: PayloadAction<UpdateUser>) {
      let updatedUser = action.payload;
      if (state.employerInformation) {
        state.employerInformation.users[updatedUser.index] = updatedUser.user;
      }
    },
    setSelectedProviders(
      state: EmployerState,
      action: PayloadAction<Provider[]>,
    ) {
      state.selectedProviders = action.payload;
    },
    setAllEmployers(state: EmployerState, action: PayloadAction<Employer[]>) {
      state.allEmployers = action.payload;
    },
    setCurrentEmployer(state: EmployerState, action: PayloadAction<Employer>) {
      state.currentEmployer = action.payload;
    },
  },
});

export default employerSlice;
