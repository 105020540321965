type CalloutType = "error" | "generic" | "success";

export const Callout = ({
  children,
  type = "generic",
}: {
  children: React.ReactNode;
  type?: string;
}) => {
  let color = "";
  switch (type) {
    case "error":
      color = "bg-[#FFDDD5] text-[#BD0300]";
      break;
    case "success":
      color = "bg-[#C9EEC9] text-[#056753]";
      break;
    case "generic":
      color = "bg-[#F3F9FF] text-[#105A9A";
      break;
  }

  return (
    <div className={`flex flex-row gap-2 items-center rounded-lg p-2 ${color}`}>
      {children}
    </div>
  );
};
