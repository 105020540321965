import { Typography } from "@mui/material";

export const Step = ({
  content,
  svg,
}: {
  content: string;
  svg: React.ReactNode;
}) => {
  return (
    <div className="flex justify-between self-stretch w-full flex-row items-center lg:flex-col text-center gap-2 rounded-lg shadow-light-earth bg-white text-start overflow-hidden max-w-[600px]">
      <div className="px-4 py-4">
        <Typography
          variant="titleM"
          sx={{
            color: "#212529",
          }}
        >
          {content}
        </Typography>
      </div>
      <div className="self-end">{svg}</div>
    </div>
  );
};
