import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";

import Header, { HeaderProps } from "../Header";
import { PageMargins } from "./PageMargins";

export const HealthKeyPage = ({
  pageTitle,
  headerProps,
  fullWidth,
  children,
}: {
  pageTitle?: string;
  headerProps: Omit<HeaderProps, "hamburgerOverride"> | null;
  fullWidth?: boolean;
  children: React.ReactNode;
}) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const ref = useRef<HTMLDivElement>(null);
  const [observing, setObserving] = useState(null as null | HTMLDivElement);
  const [height, setHeight] = useState(80); // Prevents a bounce on first render by defaulting to default height -- LH, 2024-08-12
  const [hamburgerOverride, setHamburgerOverride] = useState(false);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (ref.current) {
        const { current } = ref;
        const boundingRect = current.getBoundingClientRect();
        setHeight(boundingRect.height);

        if (boundingRect.height > 80) {
          setHamburgerOverride(true);
        }
      }
    });

    if (ref?.current && !observing) {
      resizeObserver.observe(ref?.current);
      setObserving(ref?.current);
    }

    return () => {
      if (observing) {
        resizeObserver.unobserve(observing);
        setObserving(null);
      }
    };
  }, [observing, ref]);

  return (
    <PageMargins fullWidth={fullWidth}>
      <Helmet>
        <title>HealthKey{pageTitle ? ` | ${pageTitle}` : ""}</title>
      </Helmet>
      {headerProps !== null && (
        <Header
          {...headerProps}
          ref={ref}
          hamburgerOverride={hamburgerOverride}
        />
      )}
      {/* The padding-top makes room for the fixed position AppBar, we don't need this when we don't show the header -- LH, 2024-02-23 */}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "flex-col", sm: "flex-row" },
          paddingTop: headerProps === null ? "0" : `${height}px`,
        }}
      >
        {children}
      </Box>
    </PageMargins>
  );
};
