import { styled } from "@mui/material/styles";
import Tabs, { TabsProps } from "@mui/material/Tabs";
import * as React from "react";

type StyledTabsProps = {
  children?: React.ReactNode;
  value: string | boolean;
  onChange: (event: React.SyntheticEvent, newValue: string) => void;
} & TabsProps;

export const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  minHeight: "0",
  flexGrow: "1",
  display: "flex",
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#A60101",
    borderRadius: "10px",
  },
});
