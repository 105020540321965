import { ArrowForward, ArrowRight, ChevronRight } from "@mui/icons-material";
import { Fragment } from "react";

export const Steps = ({ steps }: { steps: React.ReactNode[] }) => {
  return (
    <div className="flex flex-col lg:flex-row items-center gap-2 sm:mx-[-24px] pb-6">
      {steps.map((step, index) => (
        <Fragment key={index}>
          {step}
          {index < steps.length - 1 && (
            <>
              <div className="rotate-90 lg:rotate-0">
                <ChevronRight
                  sx={{
                    color: "#2C2C2C",
                    width: "24px",
                    height: "25px",
                  }}
                />
              </div>
            </>
          )}
        </Fragment>
      ))}
    </div>
  );
};
