import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { combineReducers, UnknownAction } from "redux";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import { authErrorHandler } from "./middleware/error-handler";
import authSlice from "./slices/auth";
import employerSlice from "./slices/employer";
import employerBasketSlice from "./slices/employerBasket";
import { healthkeyAPI } from "./slices/healthkeyAPI";
import insurerSlice from "./slices/insurer";
import providersSlice from "./slices/providers";
import searchSlice from "./slices/search";
import servicesSlice from "./slices/services";
import subscriptionsSlice from "./slices/subscriptions";
import transactionsSlice from "./slices/transactions";
import userDetailsSlice from "./slices/userDetails";
import usersSlice from "./slices/users";

const appReducer = combineReducers({
  auth: authSlice.reducer,
  userDetails: userDetailsSlice.reducer,
  transactions: transactionsSlice.reducer,
  providers: providersSlice.reducer,
  subscriptions: subscriptionsSlice.reducer,
  insurers: insurerSlice.reducer,
  users: usersSlice.reducer,
  employer: employerSlice.reducer,
  employerBasket: employerBasketSlice.reducer,
  search: searchSlice.reducer,
  services: servicesSlice.reducer,
  [healthkeyAPI.reducerPath]: healthkeyAPI.reducer,
});

const rootReducer = (state: any, action: UnknownAction) => {
  if (action.type === "auth/logout") {
    storage.removeItem("persist:root");
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const persistedReducer = persistReducer(
  {
    key: "root",
    version: 1,
    storage: storage,
    blacklist: [healthkeyAPI.reducerPath],
  },
  rootReducer,
);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(healthkeyAPI.middleware)
      .concat(authErrorHandler),
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
