import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { ReactNode, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { useAppSelector } from "../store/hooks";
import { useEmployerForUserQuery } from "../store/slices/healthkeyAPI";

const usePageTitle = (callback: (titleText: string) => void) => {
  useEffect(() => {
    const observer = new MutationObserver(function (mutations) {
      const titleText = (mutations[0].target as HTMLElement).innerText;
      if (titleText) {
        callback(titleText);
      }
    });
    observer.observe(document.querySelector("title") as any, {
      subtree: true,
      characterData: true,
      childList: true,
    });
    return () => observer.disconnect();
  }, [callback]);
};

const calculateCustomDimensions = (
  loggedInEmployer: any,
  loggedInUser: any,
) => {
  return [
    {
      id: 1,
      value: loggedInEmployer?.id,
    },
    {
      id: 2,
      value: loggedInUser?.id ? "true" : "false",
    },
  ];
};

export const MatomoHistoryListener = ({
  children,
}: {
  children: ReactNode;
}) => {
  const location = useLocation();
  const { trackPageView } = useMatomo();
  const loggedInUser = useAppSelector((state) => state.userDetails.user);
  const { data: loggedInEmployer, isLoading: loggedInEmployerIsLoading } =
    useEmployerForUserQuery(loggedInUser?.id, {
      skip: !loggedInUser?.id,
    });

  const [eventCache, setEventCache] = useState<any[]>([]);

  if (eventCache.length > 0 && !loggedInEmployerIsLoading) {
    eventCache.forEach((event) => {
      trackPageView({
        ...event,
        customDimensions: calculateCustomDimensions(
          loggedInEmployer,
          loggedInUser,
        ),
      });
    });
    setEventCache([]);
  }

  const updatedPageTitle = (titleText: string) => {
    const event = {
      documentTitle: titleText,
      href: `${process.env.REACT_APP_HOSTNAME}${location.pathname}`,
    };

    if (!loggedInEmployerIsLoading || !loggedInUser?.id) {
      trackPageView({
        ...event,
        customDimensions: calculateCustomDimensions(
          loggedInEmployer,
          loggedInUser,
        ),
      });
    } else {
      setEventCache([...eventCache, event]);
    }
  };

  usePageTitle(updatedPageTitle);

  return <>{children}</>;
};
