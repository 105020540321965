import { AxiosError } from "axios";
import moment from "moment";

import authSlice from "../store/slices/auth";
import { HealthCategory, Product } from "../types/types";
import { ReactComponent as ChronicSVG } from "./../images/chronic-disease.svg";
import { ReactComponent as EnergySVG } from "./../images/energy-stamina-strength.svg";
import { ReactComponent as InsightsSVG } from "./../images/health-insights.svg";
import { ReactComponent as LongevitySVG } from "./../images/longevity.svg";
import { ReactComponent as MentalWellbeingSVG } from "./../images/mental-wellbeing.svg";
import { ReactComponent as ParentingSVG } from "./../images/parenting.svg";
import { ReactComponent as PhysiotherapySVG } from "./../images/physiotherapy.svg";
import { ReactComponent as ForYouSVG } from "./../images/Services for you.svg";
import { ReactComponent as SexualHealthSVG } from "./../images/sexual-health.svg";
import { ReactComponent as SocialSVG } from "./../images/social-and-relationships.svg";
import { ReactComponent as WomenSVG } from "./../images/womans-health.svg";

export const handleErrorResponse = (
  err: AxiosError,
  dispatch: any,
  navigate: any,
  sessionExpiredUrl: string,
) => {
  if (
    err.response &&
    err.response.status &&
    (err.response!.status === 401 || err.response!.status === 403)
  ) {
    dispatch(authSlice.actions.logout());
    navigate(sessionExpiredUrl);
  }
};

export const buildSessionExpiredUrl = (
  response_type: string | null,
  client_id: string | null,
  redirect_uri: string | null,
  scope: string | null,
  code_challenge: string | null,
  code_challenge_method: string | null,
  nonce: string | null,
  state: string | null,
  claims: string | null,
) => {
  let path = "/sessionExpired";
  if (response_type) {
    path = path + "?response_type=" + response_type + "&";
    path = path + "client_id=" + client_id + "&";
    path = path + "redirect_uri=" + redirect_uri + "&";
    path = path + "scope=" + scope + "&";
    path = path + "code_challenge=" + code_challenge + "&";
    path = path + "code_challenge_method=" + code_challenge_method + "&";
    path = path + "nonce=" + nonce + "&";
    path = path + "state=" + state + "&";
    path = path + "claims=" + claims + "&";
  }
  return path;
};

export const buildSessionExpiredUrl2 = (
  response_type: string | null,
  client_id: string | null,
  redirect_uri: string | null,
  scope: string | null,
  code_challenge: string | null,
  code_challenge_method: string | null,
  nonce: string | null,
  state: string | null,
  claims: string | null,
) => {
  let path = "/sessionExpired";
  if (response_type) {
    path = path + "?response_type=" + response_type + "&";
    path = path + "client_id=" + client_id + "&";
    path = path + "redirect_uri=" + redirect_uri + "&";
    path = path + "scope=" + scope + "&";
    path = path + "code_challenge=" + code_challenge + "&";
    path = path + "code_challenge_method=" + code_challenge_method + "&";
    path = path + "nonce=" + nonce + "&";
    path = path + "state=" + state + "&";
    path = path + "claims=" + claims + "&";
  }
  return path;
};

export const getHealthCategoryById = (
  id: string | undefined,
  healthCategories: HealthCategory[],
): HealthCategory | undefined => {
  if (id) {
    for (let category of healthCategories) {
      if (category.id === id) {
        return category;
      }
    }
  }
  return undefined;
};

export const getHealthCategoryByHtmlId = (
  id: string | undefined,
  healthCategories: HealthCategory[],
): HealthCategory | undefined => {
  if (id) {
    for (let category of healthCategories) {
      if (category.htmlId === id) {
        return category;
      }
    }
  }
  return undefined;
};
export const getParsedDate = (date) => {
  const dateDate = new Date(date);
  return dateDate.toLocaleString("en-GB");
};

export const getParsedDateNoTime = (date) => {
  const dateDate = new Date(date);
  return dateDate.toLocaleDateString("en-GB");
};

export const isServiceInTheList = (
  serviceId: string,
  servicesList: Array<Product>,
): boolean => {
  let exists = false;
  for (let service of servicesList) {
    if (serviceId === service.id) {
      exists = true;
    }
  }
  return exists;
};

export const objToStrMap = (obj: { [x: string]: any }) => {
  let strMap = new Map();
  for (let k of Object.keys(obj)) {
    strMap.set(k, obj[k]);
  }
  return strMap;
};

export const getWindowLocationPathname = () => {
  return window.location.pathname;
};

export const healthAreaSVG = (
  healthAreaId: string | undefined,
): React.ReactNode => {
  switch (healthAreaId) {
    case "phc_48dce0362d0644eeb07d753d1c27f3ae":
      return <MentalWellbeingSVG />;
    case "phc_a748018d1981420caaa84ac8605d54ba":
      return <SexualHealthSVG />;
    case "phc_e1f5cf1b06b8465d856e64291c3ad052":
      return <ChronicSVG />;
    case "phc_9987072a2a1a4236b4e6d5b21fc37269":
      return <WomenSVG />;
    case "phc_971fbae6f19e424a96740179058f98ca":
      return <InsightsSVG />;
    case "phc_eb35cb1f808a4c9baa4bb645af7be6ac":
      return <SocialSVG />;
    case "phc_29e257393aa44597b7ded08addf77835":
      return <LongevitySVG />;
    case "phc_80fb3f1444aa4e1c8de7c257e9ff3293":
      return <EnergySVG />;
    case "phc_6bf137b6a3834579817d02fe0a1397ec":
      return <ParentingSVG />;
    case "phc_82b403ca5a2a4076b454d15f41ef1113":
      return <PhysiotherapySVG />;
    case "for_you":
      return <ForYouSVG />;
    default:
      return <div />;
  }
};
export const formatNumberAsCurrency = (
  value: number,
  numberFormatOptions: Intl.NumberFormatOptions & {
    trailingZeroDisplay?: "stripIfInteger";
  } = {},
): string => {
  return (value + 0).toLocaleString("en-GB", {
    style: "currency",
    currency: "GBP",
    ...numberFormatOptions,
  }); // Avoid this formatting as negative zero by adding zero https://stackoverflow.com/a/77037516 -- LH, 2024-06-10
};

export const truncate = (str: string, maxLength: number = 5) => {
  if (str.length <= maxLength) {
    return str;
  } else {
    return str.slice(0, maxLength - 3) + "...";
  }
};

export const getCurrentMoment = () => {
  return moment();
};

export const generateFileNameWithDate = (n: string): string => {
  return n + "-" + moment(new Date()).format("YYYY-MM-DD");
};
