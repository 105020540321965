import { Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import { lazy, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { HealthKeyContainer, HealthKeyPage } from "../components/design-system";
import LogoBanner from "../components/new/LogoCenteredInsideComponent";
import authSlice from "../store/slices/auth";

const FlagSVG = lazy(() =>
  import("./../images/404-sign-lg.svg").then((module) => ({
    default: module.ReactComponent,
  })),
);
const CloudSVG = lazy(() =>
  import("./../images/404-cloud-lg.svg").then((module) => ({
    default: module.ReactComponent,
  })),
);

function ErrorPage() {
  const theme = useTheme();
  const { t } = useTranslation();

  const isBiggerThanLgBreakpoint = useMediaQuery(theme.breakpoints.up("lg"));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(authSlice.actions.logout());
  }, [dispatch]);

  return (
    <HealthKeyContainer>
      <LogoBanner />
      <HealthKeyPage headerProps={null} pageTitle="Error">
        <div className="w-full h-full py-20 flex flex-col lg:flex-row gap-4">
          <div className="flex flex-row">
            <FlagSVG />
            {!isBiggerThanLgBreakpoint && (
              <div className="ml-auto hidden sm:block">
                <CloudSVG />
              </div>
            )}
          </div>
          <div className="w-full lg:w-[600px] flex flex-col gap-8 mx-auto">
            <div className="w-full lg:w-[600px] flex flex-col gap-2 lg:gap-4">
              <Typography
                variant="titleXXXL"
                sx={{ textAlign: { xs: "left", lg: "center" } }}
              >
                {t("error-page.title")}
              </Typography>
              <Typography
                variant="bodyL"
                sx={{ textAlign: { xs: "left", lg: "center" } }}
              >
                {t("error-page.text")}
              </Typography>
            </div>
            <div className="flex flex-col gap-2 lg:flex-row w-full items-center justify-center">
              <Button
                variant="contained"
                onClick={() => navigate("/")}
                sx={{ width: { xs: "100%", md: "320px", lg: "auto" } }}
              >
                {t("error-page.button-home")}
              </Button>
              <Button
                variant="outlined"
                onClick={() => navigate("/findCare")}
                sx={{ width: { xs: "100%", md: "320px", lg: "auto" } }}
              >
                {t("error-page.button-care")}
              </Button>
            </div>
          </div>
          {isBiggerThanLgBreakpoint && <CloudSVG />}
        </div>
      </HealthKeyPage>
    </HealthKeyContainer>
  );
}

export default ErrorPage;
