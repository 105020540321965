import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import moment from "moment";

import {
  AddUserToInsurer,
  AffordableProducts,
  AnalyticsEvent,
  BudgetHistory,
  ContactUs,
  Content,
  ContentForHealthCategory,
  CreateServicePackagePaymentResponse,
  Employer,
  EmployerForUser,
  EmployerInformation,
  EmployerInsights,
  EmployerMonthlyBudgetInformation,
  EmployerPayment,
  FeatureBundle,
  FinishPurchase,
  HealthCategory,
  Insurer,
  Product,
  ProductForPublic,
  ProductsForHealthCategory,
  Purchase,
  PurchaseTransaction,
  RemoveUserFromEmployer,
  RemoveUserFromInsurer,
  RespondToUserJoiningEmployer,
  SalarySacrificeReportParam,
  ServicePackage,
  StackOneAccount,
  StackOneConnectSession,
  StackOneCreateConnectSession,
  StackOneImportPreview,
  TestingUserId,
  TopUp,
  UpdateProvidersForEmployer,
  UpdateServiceBody,
  UpdateServicePurchaseJourney,
  UserForSalarySacrificeReport,
  UserHealthCategoriesPreferences,
  UserInfo,
  Wallet,
} from "../../types/types";
import { cacheTime, Entity } from "./api/definitions";
import authSlice from "./auth";

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_SERVER_API_URL}`,
  prepareHeaders: (headers, { getState, endpoint }) => {
    const formDataEndpoints = ["uploadEmployerImage"];
    // We do not set the content type for multipart form data as fetch will do this with the boundary -- LH, 2024-04-04
    if (!formDataEndpoints.includes(endpoint)) {
      headers.set("Content-Type", "application/json");
    }
    const token = (getState() as any).auth.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
  responseHandler: async (response) => {
    switch (response.headers.get("content-type")) {
      case "application/json":
        return response.json();
      default:
        return response.text();
    }
  },
});

const baseQueryPython = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_URL}`,
  prepareHeaders: (headers, { getState, endpoint }) => {
    const token = (getState() as any).auth.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
  responseHandler: async (response) => {
    switch (response.headers.get("content-type")) {
      case "application/json":
        return response.json();
      default:
        return response.text();
    }
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.status === 401) {
    console.log("sending refresh token");
    // send refresh token to get new access token
    const refreshResult = await baseQueryPython(
      {
        url: "/token/refresh/",
        body: { refresh: (api.getState() as any).auth.refreshToken },
        method: "POST",
      },
      api,
      extraOptions,
    );
    console.log(refreshResult);
    if (refreshResult.data) {
      // store the new token
      api.dispatch(authSlice.actions.refreshAuthToken(refreshResult.data));
      // retry the original query with new access token
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(authSlice.actions.logout());
    }
  }

  return result;
};

export const healthkeyAPI = createApi({
  reducerPath: "healthkeyAPI",
  tagTypes: [
    Entity.Employer,
    Entity.EmployersForProvider,
    Entity.EmployerForUser,
    Entity.ProviderToEdit,
    Entity.Provider,
    Entity.Wallet,
    Entity.Purchase,
    Entity.EmployerInfo,
    Entity.EmployerInsights,
    Entity.EmployerBudgetInfo,
    Entity.HealthCategory,
    Entity.Service,
    Entity.Wishlist,
    Entity.BudgetHistory,
    Entity.InsurerForUser,
    Entity.HealthCategoryPreference,
    Entity.ProviderHealthCategoryPreference,
    Entity.StackOneConnectedAccount,
    Entity.Content,
    Entity.EmployersForAdmin,
    Entity.ServicesForAdmin,
    Entity.ContentForAdmin,
    Entity.AdminsForProvider,
  ],
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    walletForUser: builder.query<Wallet, string | undefined>({
      keepUnusedDataFor: cacheTime[Entity.Wallet],
      query: (userId: string) => `/users/${userId}/wallet`,
      providesTags: [Entity.Wallet],
    }),
    purchasesForUser: builder.query<Purchase[], string | undefined>({
      keepUnusedDataFor: cacheTime[Entity.Purchase],
      query: (userId: string) => `/users/${userId}/purchases`,
      providesTags: [Entity.Purchase],
    }),
    providerHealthCategories: builder.query<HealthCategory[], void>({
      keepUnusedDataFor: cacheTime[Entity.HealthCategory],
      query: () => `/providerHealthCategories`,
    }),
    providerHealthCategoriesChildren: builder.query<
      Map<string, HealthCategory[]>,
      void
    >({
      keepUnusedDataFor: cacheTime[Entity.HealthCategory],
      query: () => `/providerHealthCategories/children`,
    }),
    providerHealthCategoryChildren: builder.query<HealthCategory[], string>({
      keepUnusedDataFor: cacheTime[Entity.HealthCategory],
      query: (providerHealthCategoryId: string) =>
        `/providerHealthCategories/children/${providerHealthCategoryId}`,
    }),
    serviceHealthCategories: builder.query<HealthCategory[], void>({
      keepUnusedDataFor: cacheTime[Entity.HealthCategory],
      query: () => `/healthCategories`,
    }),
    servicesHealthCategoriesMap: builder.query<
      Record<string, ProductsForHealthCategory>,
      void
    >({
      keepUnusedDataFor: cacheTime[Entity.Service],
      query: () => `/products/healthCategories`,
    }),
    contentHealthCategoriesMap: builder.query<
      Record<string, ContentForHealthCategory>,
      void
    >({
      keepUnusedDataFor: cacheTime[Entity.Content],
      query: () => `/contents/healthCategories`,
    }),
    insurers: builder.query<Insurer[], void>({
      keepUnusedDataFor: cacheTime[Entity.Insurer],
      query: () => `/insurers`,
    }),
    filteredProduct: builder.query<
      Product[],
      { categories: string[]; searchQuery?: string | undefined }
    >({
      keepUnusedDataFor: cacheTime[Entity.Service],
      query: ({ categories, searchQuery }) =>
        `/products${searchQuery || categories.length > 0 ? `?` : ""}${searchQuery ? `searchQuery=` + searchQuery : ""}${categories.length > 0 ? categories.map((n) => `category=${n}`).join("&") : ""}`,
    }),
    affordableProducts: builder.query<AffordableProducts, void>({
      keepUnusedDataFor: cacheTime[Entity.Service],
      query: () => `/products/affordable`,
    }),
    filteredContent: builder.query<
      Content[],
      { categories: string[]; searchQuery?: string | undefined }
    >({
      keepUnusedDataFor: cacheTime[Entity.Content],
      query: ({ categories, searchQuery }) =>
        `/contents${searchQuery || categories.length > 0 ? `?` : ""}${searchQuery ? `searchQuery=` + searchQuery : ""}${categories.length > 0 ? categories.map((n) => `category=${n}`).join("&") : ""}`,
      providesTags: [Entity.Content],
    }),
    product: builder.query<Product, string>({
      query: (serviceId: string) => `/products/${serviceId}`,
    }),
    content: builder.query<Content, string | undefined>({
      query: (contentId: string | undefined) => `/contents/${contentId}`,
      keepUnusedDataFor: cacheTime[Entity.Content],
      providesTags: [Entity.Content],
    }),
    similarProducts: builder.query<
      Product[],
      {
        serviceId: string;
        userId: string;
      }
    >({
      keepUnusedDataFor: cacheTime[Entity.Service],
      query: ({ serviceId, userId }) =>
        `/products/${serviceId}/similar/user/${userId}`,
    }),
    similarProductsPublic: builder.query<
      ProductForPublic[],
      {
        serviceId: string;
      }
    >({
      keepUnusedDataFor: cacheTime[Entity.Service],
      query: ({ serviceId }) => `/products/${serviceId}/similar`,
    }),
    productsInProviderHealthCategory: builder.query<
      Product[],
      {
        providerHealthCategoryId: string;
        userId: string;
      }
    >({
      keepUnusedDataFor: cacheTime[Entity.Service],
      query: ({ providerHealthCategoryId, userId }) =>
        `/products/healthCategory/${providerHealthCategoryId}/user/${userId}`,
    }),
    contentInProviderHealthCategory: builder.query<
      Content[],
      {
        providerHealthCategoryId: string;
      }
    >({
      keepUnusedDataFor: cacheTime[Entity.Content],
      query: ({ providerHealthCategoryId }) =>
        `/contents/healthCategory/${providerHealthCategoryId}`,
    }),
    productInProviderHealthCategoryPublic: builder.query<
      ProductForPublic[],
      {
        providerHealthCategoryId: string;
      }
    >({
      keepUnusedDataFor: cacheTime[Entity.Service],
      query: ({ providerHealthCategoryId }) =>
        `/products/healthCategory/${providerHealthCategoryId}`,
    }),
    wishlistForUser: builder.query<Product[], string | undefined>({
      providesTags: [Entity.Wishlist],
      keepUnusedDataFor: cacheTime[Entity.Wishlist],
      query: (userId: string) => `/users/${userId}/wishlist`,
    }),
    budgetHistory: builder.query<BudgetHistory[], string | undefined>({
      providesTags: [Entity.BudgetHistory],
      keepUnusedDataFor: cacheTime[Entity.BudgetHistory],
      query: (userId: string) => `/users/${userId}/budgetHistory`,
    }),
    publishedEmployers: builder.query<Employer[], void>({
      keepUnusedDataFor: cacheTime[Entity.Employer],
      query: () => `/employers?published=true`,
    }),
    employerForUser: builder.query<EmployerForUser, string | undefined>({
      keepUnusedDataFor: cacheTime[Entity.EmployerForUser],
      query: (userId: string) => ({
        url: `/users/${userId}/employer`,
      }),
      providesTags: [Entity.EmployerForUser],
    }),
    insurerForUser: builder.query<Insurer, string | undefined>({
      keepUnusedDataFor: cacheTime[Entity.InsurerForUser],
      query: (userId: string) => ({
        url: `/users/${userId}/insurer`,
      }),
      providesTags: [Entity.InsurerForUser],
    }),
    healthCategoriesPreference: builder.query<
      UserHealthCategoriesPreferences,
      string | undefined
    >({
      keepUnusedDataFor: cacheTime[Entity.HealthCategoryPreference],
      query: (userId: string) => `/users/${userId}/healthCategoriesPreferences`,
      providesTags: [Entity.HealthCategoryPreference],
    }),
    updateHealthCategoriesPreference: builder.mutation<
      UserHealthCategoriesPreferences,
      UserHealthCategoriesPreferences
    >({
      query: (updatePreferences: UserHealthCategoriesPreferences) => ({
        method: "PUT",
        body: {
          categoriesIds: updatePreferences.categoriesIds,
        },
        url: `/users/${updatePreferences.userId}/healthCategoriesPreferences`,
      }),
      async onQueryStarted({ userId }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedPreferences } = await queryFulfilled;
          dispatch(
            healthkeyAPI.util.updateQueryData(
              "healthCategoriesPreference",
              userId,
              (draft) => {
                Object.assign(draft, updatedPreferences);
              },
            ),
          );
        } catch {
          //nothing to catch}
        }
      },
    }),
    providerHealthCategoriesPreference: builder.query<
      UserHealthCategoriesPreferences,
      string | undefined
    >({
      keepUnusedDataFor: cacheTime[Entity.ProviderHealthCategoryPreference],
      query: (userId: string) =>
        `/users/${userId}/providerHealthCategoriesPreferences`,
      providesTags: [Entity.ProviderHealthCategoryPreference],
    }),
    updateProviderHealthCategoriesPreference: builder.mutation<
      UserHealthCategoriesPreferences,
      UserHealthCategoriesPreferences
    >({
      query: (updatePreferences: UserHealthCategoriesPreferences) => ({
        method: "PUT",
        body: {
          categoriesIds: updatePreferences.categoriesIds,
        },
        url: `/users/${updatePreferences.userId}/providerHealthCategoriesPreferences`,
      }),
      async onQueryStarted({ userId }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedPreferences } = await queryFulfilled;
          dispatch(
            healthkeyAPI.util.updateQueryData(
              "providerHealthCategoriesPreference",
              userId,
              (draft) => {
                Object.assign(draft, updatedPreferences);
              },
            ),
          );
        } catch {
          //nothing to catch}
        }
      },
    }),
    currentEmployer: builder.query<Employer, string | undefined>({
      keepUnusedDataFor: cacheTime[Entity.Employer],
      query: (employerId: string) => ({
        url: `/employers/${employerId}/full`,
      }),
      providesTags: [Entity.Employer],
    }),
    partialEmployer: builder.query<Employer, string | undefined>({
      query: (employerId: string) => ({
        url: `/employers/${employerId}/partial`,
      }),
    }),
    employerInfo: builder.query<EmployerInformation, string | undefined>({
      keepUnusedDataFor: cacheTime[Entity.EmployerInfo],
      query: (employerId: string) => ({
        url: `/employers/${employerId}/employerInfo`,
      }),
      providesTags: [Entity.EmployerInfo],
    }),
    employerInsights: builder.query<EmployerInsights, string | undefined>({
      keepUnusedDataFor: cacheTime[Entity.EmployerInsights],
      query: (employerId: string) => ({
        url: `/employers/${employerId}/insights`,
      }),
      providesTags: [Entity.EmployerInsights],
    }),
    employerBudgetInfo: builder.query<
      Record<string, EmployerMonthlyBudgetInformation>,
      string | undefined
    >({
      keepUnusedDataFor: cacheTime[Entity.EmployerBudgetInfo],
      query: (employerId: string) => ({
        url: `/employers/${employerId}/budgetInformation`,
      }),
    }),
    usersForSalarySacrificeReport: builder.query<
      UserForSalarySacrificeReport[],
      SalarySacrificeReportParam
    >({
      keepUnusedDataFor: 0,
      query: (param: SalarySacrificeReportParam) => ({
        url: `/employers/${param.employerId}/salarySacrificeReport${param.timeframe === null ? "" : "/" + param.timeframe}`,
      }),
    }),
    productsForInsurer: builder.query<Product[], string>({
      keepUnusedDataFor: cacheTime[Entity.Service],
      query: (insurerId: string) => ({
        url: `/insurers/${insurerId}/products`,
      }),
      providesTags: [Entity.Service],
    }),
    stackOneConnectedAccount: builder.query<StackOneAccount, void>({
      keepUnusedDataFor: cacheTime[Entity.StackOneConnectedAccount],
      query: () => ({
        url: `/stackone/connectedAccount`,
      }),
      providesTags: [Entity.StackOneConnectedAccount],
    }),
    stackOneImportPreview: builder.query<StackOneImportPreview, void>({
      keepUnusedDataFor: cacheTime[Entity.StackOneImportPreview],
      query: () => ({
        url: `/stackone/importPreview`,
      }),
    }),
    servicePackageById: builder.query<ServicePackage, string>({
      keepUnusedDataFor: cacheTime[Entity.ServicePackage],
      query: (servicePackageId: string) =>
        `/service-package/${servicePackageId}`,
    }),
    listServicePackages: builder.query<ServicePackage[], void>({
      keepUnusedDataFor: cacheTime[Entity.ServicePackage],
      query: () => `/service-package/list/acquired`,
    }),
    listPurchaseableServicePackages: builder.query<ServicePackage[], void>({
      keepUnusedDataFor: cacheTime[Entity.ServicePackage],
      query: () => `/service-package/list/purchasable`,
    }),
    listPurchasableBundles: builder.query<FeatureBundle[], void>({
      keepUnusedDataFor: cacheTime[Entity.FeatureBundle],
      query: () => `/featureBundles/list/purchasable`,
    }),
    updateService: builder.mutation<void, UpdateServiceBody>({
      query: (updatedService: UpdateServiceBody) => ({
        method: "PATCH",
        body: updatedService,
        url: `/products/${updatedService.serviceId}`,
      }),
      invalidatesTags: [Entity.Service, Entity.ServicesForAdmin],
    }),
    updateServicePurchaseJourney: builder.mutation<
      void,
      UpdateServicePurchaseJourney
    >({
      query: (updateService: UpdateServicePurchaseJourney) => ({
        method: "PATCH",
        body: {
          purchaseJourneyFirstStep: updateService.firstStep,
          purchaseJourneySecondStep: updateService.secondStep,
          purchaseJourneyThirdStep: updateService.thirdStep,
        },
        url: `/products/${updateService.serviceId}`,
      }),
      invalidatesTags: [Entity.Service, Entity.ServicesForAdmin],
    }),
    updateProvidersForEmployer: builder.mutation<
      void,
      UpdateProvidersForEmployer
    >({
      query: (updateProviders: UpdateProvidersForEmployer) => ({
        method: "PATCH",
        body: { selectedProvidersIds: updateProviders.selectedProvidersIds },
        url: `/employers/${updateProviders.employerId}`,
      }),
      invalidatesTags: [Entity.Provider],
    }),

    acceptTermsAndConditions: builder.mutation<Employer, string>({
      query: (employerId: string) => ({
        method: "PATCH",
        url: `/employers/${employerId}/acceptTAndC`,
      }),
      invalidatesTags: [Entity.Employer],
    }),
    createEmployer: builder.mutation<
      Employer,
      Pick<Employer, "name" | "employerSize" | "parentEmployerId">
    >({
      query: (employer) => ({
        method: "POST",
        url: `/employers`,
        body: employer,
      }),
      invalidatesTags: [Entity.EmployerForUser, Entity.Employer],
    }),
    updateEmployer: builder.mutation<Employer, Partial<Employer>>({
      query: (employer) => ({
        method: "PUT",
        url: `/employers/${employer.id}`,
        body: employer,
      }),
      invalidatesTags: [Entity.EmployerForUser, Entity.Employer],
    }),

    updateContent: builder.mutation<Content, Partial<Content>>({
      query: (content) => ({
        method: "PATCH",
        url: `/contents/${content.id}`,
        body: content,
      }),
      invalidatesTags: [Entity.Content, Entity.ContentForAdmin],
    }),
    createContent: builder.mutation<Content, Partial<Content>>({
      query: (content) => ({
        method: "POST",
        url: `/contents`,
        body: content,
      }),
      invalidatesTags: [Entity.Content, Entity.ContentForAdmin],
    }),
    removeUserFromEmployer: builder.mutation<void, RemoveUserFromEmployer>({
      query: (removeUser: RemoveUserFromEmployer) => ({
        method: "DELETE",
        url: `/employers/${removeUser.employerId}/users/${removeUser.userId}`,
      }),
      invalidatesTags: [
        Entity.EmployerForUser,
        Entity.Employer,
        Entity.EmployerInfo,
      ],
    }),
    removeUserFromInsurer: builder.mutation<void, RemoveUserFromInsurer>({
      query: (removeUser: RemoveUserFromInsurer) => ({
        method: "DELETE",
        url: `/insurers/${removeUser.insurerId}/users/${removeUser.userId}`,
      }),
      invalidatesTags: [Entity.InsurerForUser],
    }),
    deleteEmployer: builder.mutation<void, string>({
      query: (employerId) => ({
        method: "DELETE",
        url: `/employers/${employerId}`,
      }),
      invalidatesTags: [Entity.EmployersForAdmin],
    }),
    finishPurchase: builder.mutation<void, FinishPurchase>({
      query: (finishPurchase: FinishPurchase) => ({
        method: "PATCH",
        body: {
          useSalarySacrifice: finishPurchase.salarySacrifice ? true : false,
        },
        url: `/transactions/${finishPurchase.transactionId}/${finishPurchase.action}`,
      }),
      invalidatesTags: [Entity.Wallet, Entity.Purchase, Entity.BudgetHistory],
    }),
    startPurchase: builder.mutation<void, PurchaseTransaction>({
      query: (startPurchase) => ({
        method: "POST",
        body: startPurchase,
        url: `/transactions`,
      }),
      invalidatesTags: [Entity.Purchase],
    }),
    finishTopUpPayment: builder.mutation<void, string>({
      query: (paymentIntent: string) => ({
        method: "PATCH",
        url: `/payments/finaliseTopUpPaymentIntent/${paymentIntent}`,
      }),
      invalidatesTags: [Entity.Wallet, Entity.BudgetHistory],
    }),
    respondToUserJoiningEmployer: builder.mutation<
      EmployerInformation,
      RespondToUserJoiningEmployer
    >({
      query: (respond: RespondToUserJoiningEmployer) => ({
        method: "PATCH",
        body: {
          approve: respond.approve,
        },
        url: `/employers/${respond.employerId}/users/${respond.userId}`,
      }),
      invalidatesTags: [Entity.EmployerInfo],
    }),
    contactUs: builder.mutation<void, ContactUs>({
      query: (contactUs: ContactUs) => ({
        method: "POST",
        body: contactUs,
        url: `/admin/contactUs`,
      }),
      invalidatesTags: [Entity.Wallet, Entity.Purchase, Entity.BudgetHistory],
    }),
    uploadEmployerImage: builder.mutation<
      void,
      { type: string; imageFile: File }
    >({
      query: ({ type, imageFile }: { type: string; imageFile: File }) => {
        const bodyFormData = new FormData();
        bodyFormData.append("file", imageFile);
        return {
          method: "POST",
          url: `/employers/uploadImage/${type}`,
          body: bodyFormData,
          formData: true,
        };
      },
      invalidatesTags: [Entity.EmployerForUser, Entity.Employer],
    }),
    updateUserDetails: builder.mutation<
      void,
      { userId: string; userInfo: UserInfo }
    >({
      invalidatesTags: [Entity.EmployerInfo],
      query: ({ userId, userInfo }) => ({
        method: "PUT",
        url: `/users/${userId}`,
        body: userInfo,
      }),
    }),
    topupUser: builder.mutation<void, TopUp>({
      invalidatesTags: [Entity.EmployerInfo],
      query: (topup) => ({
        method: "POST",
        url: `/transactions/topUp`,
        body: topup,
      }),
    }),
    addServiceToWishlist: builder.mutation<
      void,
      {
        userId: string;
        serviceId: string;
      }
    >({
      invalidatesTags: [Entity.Wishlist],
      query: ({ userId, serviceId }) => ({
        method: "POST",
        url: `/users/${userId}/wishlist/${serviceId}`,
      }),
    }),
    removeServiceFromWishlist: builder.mutation<
      void,
      {
        userId: string;
        serviceId: string;
      }
    >({
      invalidatesTags: [Entity.Wishlist],
      query: ({ userId, serviceId }) => ({
        method: "DELETE",
        url: `/users/${userId}/wishlist/${serviceId}`,
      }),
    }),
    addUserToInsurer: builder.mutation<void, AddUserToInsurer>({
      invalidatesTags: [Entity.InsurerForUser],
      query: (addUser: AddUserToInsurer) => ({
        method: "PUT",
        url: `/insurers/${addUser.insurerId}/users/${addUser.userId}`,
        body: { insurancePolicyNumber: addUser.insurancePolicyNumber },
      }),
    }),
    createStackOneConnectSession: builder.mutation<
      StackOneConnectSession,
      StackOneCreateConnectSession
    >({
      query: (createConnectSession: StackOneCreateConnectSession) => ({
        method: "POST",
        url: "/stackone/connectSession",
        body: createConnectSession,
      }),
    }),
    activateStackOneConnection: builder.mutation<void, void>({
      query: () => ({
        method: "POST",
        url: "/stackone/activateConnection",
      }),
      invalidatesTags: [Entity.Employer],
    }),
    deactivateStackOneConnection: builder.mutation<void, void>({
      query: () => ({
        method: "POST",
        url: "/stackone/deactivateConnection",
      }),
      invalidatesTags: [Entity.Employer, Entity.StackOneConnectedAccount],
    }),
    syncStackOne: builder.mutation<void, void>({
      query: () => ({
        method: "POST",
        url: "/stackone/sync",
      }),
      invalidatesTags: [Entity.EmployerInfo],
    }),
    createEmployerPaymentIntent: builder.mutation<
      CreateServicePackagePaymentResponse,
      EmployerPayment
    >({
      query: (createEmployerPlanPaymentIntent: EmployerPayment) => ({
        method: "POST",
        url: `/payments/createEmployerPlanPaymentIntent`,
        body: createEmployerPlanPaymentIntent,
      }),
    }),
    finalizeServicePackagePaymentIntent: builder.mutation<void, string>({
      query: (paymentIntent: string) => ({
        method: "PATCH",
        url: `/payments/finaliseEmployerPlanPaymentIntent/${paymentIntent}`,
      }),
    }),
    createAnalyticsEvent: builder.mutation<void, AnalyticsEvent>({
      query: (event: AnalyticsEvent) => ({
        method: "POST",
        url: `/analytics`,
        body: { ...event, date: moment(event.date).format("yyyy-MM-DD HH:mm") },
      }),
    }),
    resetTestData: builder.mutation<void, void>({
      query: () => ({
        method: "POST",
        url: "/testing/resetTestData",
      }),
    }),
    getUserIdForTesting: builder.query<TestingUserId, string>({
      query: (email) => ({
        url: `/testing/getUserId/${email}`,
      }),
    }),
  }),
});

export const {
  useProviderHealthCategoriesQuery,
  useProviderHealthCategoriesChildrenQuery,
  useProviderHealthCategoryChildrenQuery,
  useFilteredProductQuery,
  useProductQuery,
  useSimilarProductsQuery,
  useSimilarProductsPublicQuery,
  useProductsInProviderHealthCategoryQuery,
  useProductInProviderHealthCategoryPublicQuery,
  useServiceHealthCategoriesQuery,
  useWishlistForUserQuery,
  usePublishedEmployersQuery,
  useEmployerForUserQuery,
  useEmployerInfoQuery,
  useEmployerInsightsQuery,
  useAcceptTermsAndConditionsMutation,
  useWalletForUserQuery,
  useCreateEmployerMutation,
  useCurrentEmployerQuery,
  useUpdateEmployerMutation,
  useRemoveUserFromEmployerMutation,
  useUpdateProvidersForEmployerMutation,
  useFinishPurchaseMutation,
  usePurchasesForUserQuery,
  useStartPurchaseMutation,
  useFinishTopUpPaymentMutation,
  usePartialEmployerQuery,
  useRespondToUserJoiningEmployerMutation,
  useEmployerBudgetInfoQuery,
  useUsersForSalarySacrificeReportQuery,
  useStackOneConnectedAccountQuery,
  useStackOneImportPreviewQuery,
  useServicePackageByIdQuery,
  useListServicePackagesQuery,
  useListPurchaseableServicePackagesQuery,
  useListPurchasableBundlesQuery,
  useGetUserIdForTestingQuery,
  useUploadEmployerImageMutation,
  useAddServiceToWishlistMutation,
  useRemoveServiceFromWishlistMutation,
  useInsurersQuery,
  useAddUserToInsurerMutation,
  useBudgetHistoryQuery,
  useContactUsMutation,
  useUpdateUserDetailsMutation,
  useTopupUserMutation,
  useRemoveUserFromInsurerMutation,
  useInsurerForUserQuery,
  useProductsForInsurerQuery,
  useServicesHealthCategoriesMapQuery,
  useHealthCategoriesPreferenceQuery,
  useUpdateHealthCategoriesPreferenceMutation,
  useProviderHealthCategoriesPreferenceQuery,
  useUpdateProviderHealthCategoriesPreferenceMutation,
  useFilteredContentQuery,
  useContentInProviderHealthCategoryQuery,
  useContentHealthCategoriesMapQuery,
  useCreateStackOneConnectSessionMutation,
  useActivateStackOneConnectionMutation,
  useDeactivateStackOneConnectionMutation,
  useSyncStackOneMutation,
  useAffordableProductsQuery,
  useUpdateContentMutation,
  useCreateContentMutation,
  useCreateEmployerPaymentIntentMutation,
  useFinalizeServicePackagePaymentIntentMutation,
  useUpdateServiceMutation,
  useContentQuery,
  useCreateAnalyticsEventMutation,
  useDeleteEmployerMutation,
  useUpdateServicePurchaseJourneyMutation,
  useResetTestDataMutation,
} = healthkeyAPI;
